import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, Span } from "../components/Core";
import Helmet from "react-helmet"

const NavStyled = styled(Nav)`
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #f7f7fb;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
  }
`;

const Faq = () => {
  return (
    <>
      <Helmet>
        <title>HotSnail - Mail Scanning & Forwarding FAQs</title>
        <meta name="title" content="HotSnail - Mail Scanning & Forwarding FAQs"></meta>
        <meta name="description" content="HotSnail - Fast Answered Questions regarding HotSnail mail scanning, mail forward and mail redirection" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hotsnail.com.au/" />
        <meta property="og:title" content="HotSnail - Mail Scanning & Forwarding FAQs" />
        <meta property="og:description" content="HotSnail - Fast Answered Questions regarding HotSnail mail scanning, mail forward and mail redirection" />
        <meta property="og:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png" />

      </Helmet>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="9">
                <Title variant="hero">Frequently Asked Question</Title>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey="general">
              <Row>
                <Col md="4" className="mb-5 mb-md-0">
                  <NavStyled className="flex-column mr-md-5">
                    <Nav.Link eventKey="general">General</Nav.Link>
                    <Nav.Link eventKey="forwarding">Forwarding</Nav.Link>
                    <Nav.Link eventKey="mail">Mail</Nav.Link>
                    <Nav.Link eventKey="addresses">Addresses</Nav.Link>
                    <Nav.Link eventKey="privacy">Privacy</Nav.Link>
                  </NavStyled>
                </Col>
                <Col md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="general">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Can I be notified when I receive snail mail?
                          </Title>
                          <Text variant="small">
                            Yes, Notifications can be setup online after you login with your username and password.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Can I take it for a test drive before registering?
                          </Title>
                          <Text variant="small">
                            Sure, please request a demo login and instructional tour from the contact page.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                          What are the conditions of the Pre-Paid Credit?
                          </Title>
                          <Text variant="small">
                          Pre-paid credit is non-refundable and expires after 12 months from date of purchase. The minimum top up amount for pre-paid credit is $50.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                          How is shredding charged?
                          </Title>
                          <Text variant="small">
                          Shredding is charged per mail item, not per page. If you shred a 10-page letter, the fee is just charged once for the letter regardless of the number of pages. (Shredding is free for most plans besides the PAYG &amp; the light plan)
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                          What is the difference between the per letter and per page fee?
                          </Title>
                          <Text variant="small">
                          The per letter fee is charged when we open a letter, then each within that letter is charged as per the ‘per page’ fee
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                          Can I recieve mail addressed to a business?
                          </Title>
                          <Text variant="small">
                          Yes, by default your account will be setup to receive mail addressed to yourself, and your business (if applicable). You can also add additional names through your account.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                          Do I require Identification?
                          </Title>
                          <Text variant="small">
                          Yes, you will be required to complete a quick online identification check. <i>A video conference call is required if sending valuable to certain overseas locations.</i>
                          </Text>
                        </Box>
                        <Box mb={4}>
                          Didn’t find your answer?{" "}
                          <a href="/">
                            <Span color="primary">Contact us here</Span>
                          </a>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="forwarding">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Can I purchase something in Australia and have it shipped overseas?
                          </Title>
                          <Text variant="small">
                            Yes, HotSnail can reship your Australian purchases overseas.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Can I have a physical address for receiving deliveries?
                          </Title>
                          <Text variant="small">
                            Yes, we can provide you with a Gold Coast street address upon registration, where you can send deliveries and to use for additional addressing not suitable for PO Boxes. There will always be someone onsite to receive deliveries from between 6am until 5pm Monday to Friday and 6am to 11am on Saturdays, except public holidays.
                          </Text>
                        </Box>

                        <Box mb={4}>
                          Didn’t find your answer?{" "}
                          <a href="/contact-us">
                            <Span color="primary">Contact us here</Span>
                          </a>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="addresses">
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          I will be moving frequently, how can I change my forwarding addresses?
                          </Title>
                        <Text variant="small">
                          Easy, HotSnail can hold your mail until you have a suitable address for forwarding. Our improved forwarding system provides you with on the spot forwarding estimates based on the weight and size of your mail.
                          </Text>
                      </Box>
                      <Box mb={4}>
                        Didn’t find your answer?{" "}
                        <a href="/contact-us">
                          <Span color="primary">Contact us here</Span>
                        </a>
                      </Box>

                    </Tab.Pane>
                    <Tab.Pane eventKey="privacy">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Can anyone else read my mail?
                          </Title>
                          <Text variant="small">
                            Theoretically your mail could be read at the time of scanning, however workloads mean that mail is processed at such a rate that the operator wouldn’t have time to screen mail. All our staff undergo background police record checks and sign confidentiality agreements protecting your privacy.
                          </Text>
                        </Box>

                        <Box mb={4}>
                          Didn’t find your answer?{" "}
                          <a href="/contact-us">
                            <Span color="primary">Contact us here</Span>
                          </a>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mail">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How can I read my mail online?
                          </Title>
                          <Text variant="small">
                            HotSnail have created an intuitive and familiar website that allows you to view your mail online, very similar to how you would check your web-based email. You can also use our website to manage your mail and update your settings and accounts online.
                          </Text>
                        </Box>

                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Can I use your address to register an Australian company?
                          </Title>
                          <Text variant="small">
                            While we can not give legal advice in regards to registering an Australian company - any mail we receive we will scan to your account.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            What if I don’t want my mail opened?
                          </Title>
                          <Text variant="small">
                            No problem, you can choose what gets scanned, or just forward the mail. Nothing will be opened without your permission.
                          </Text>
                        </Box>



                        <Box mb={4}>
                          Didn’t find your answer?{" "}
                          <a href="/contact-us">
                            <Span color="primary">Contact us here</Span>
                          </a>
                        </Box>
                      </Box>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Faq;
